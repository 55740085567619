import React from 'react';
import {useTranslation} from "react-i18next";

function HomePage() {
    const [t] = useTranslation();
    return (
        <div>
            <p>{t('homeIntroduction')}</p>
            {t('statusPage.url') && (
                <a href={t('statusPage.url')}>{t('orGoToStatus')}</a>  
            )}
        </div>
    );
}

export default HomePage;