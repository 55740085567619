import React from 'react';
import {Button, IconButton, LinearProgress, Paper, Stack, Tooltip} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import * as api from '../api';
import Box from "@mui/material/Box";
import Incident, {impactName} from '../models/Incident';
import Component from "../models/Component";
import Container from "@mui/material/Container";
import dayjs from "dayjs";
import Update from "../models/Update";
import ComponentChip from "../components/ComponentChip";
import NewUpdateDialog from "../components/NewUpdateDialog";
import StatusChip from "../components/StatusChip";
import ImpactAvatar from "../components/ImpactAvatar";
import {logout} from "../App";
import EditSubscriberDialog from "../components/EditSubscriberDialog";
import Subscriber from "../models/Subscriber";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import AlertDialog from "../components/AlertDialog";
import TrashIcon from "@mui/icons-material/Delete";

function IncidentDetailPage() {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const {uuid, token} = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = React.useState(false);
    const [askDelete, setAskDelete] = React.useState(false);
    const [components, setComponents] = React.useState(null);
    const [incident, setIncident] = React.useState(null);
    const [newUpdate, setNewUpdate] = React.useState(null);
    const [subscriber, setSubscriber] = React.useState(null);
    const [edit, setEdit] = React.useState(false);
    const [askSendUpdate, setAskSendUpdate] = React.useState(null);

    const loggedIn = !!localStorage.getItem('jwt');
    if (loggedIn && (uuid || token).indexOf('-') === -1) {
        logout(`/incidents/${uuid || token}`);
        return null;
    }

    const refreshIncident = React.useCallback(() => {
        setIncident(null);
        const url = uuid ? `/api/incidents/${uuid}` : `/api/incidents/token/${token}`;
        api.get(url).then(res => {
            if(!res) return setError(true);
            if (uuid) setIncident(new Incident(res));
            else {
                setIncident(new Incident(res.incident));
                setSubscriber(new Subscriber(res.subscriber));
            }
        }, () => navigate('/'));
    }, [navigate, token, uuid]);
    
    const sendUpdate = (updateId, testEmail) => {
        api.post(`/api/incidents/${updateId}`, {testEmail}).then(() => {
            refreshIncident();
        });
        enqueueSnackbar(t('updateSent'));
    };
    
    const refreshComponents = React.useCallback(() => {
        setComponents(null);
        api.get('/api/components').then(res => {
            setComponents(res.map(i => new Component(i)));
        });
    }, []);
    React.useEffect(() => {
        if (!uuid && !token) return;
        refreshIncident();
        refreshComponents();
    }, [refreshIncident, token, uuid, refreshComponents]);

    if (!uuid && !token) {
        navigate('/');
        return null;
    }
    const jwt = localStorage.getItem('jwt');

    if (error) {
        return (
            <Stack spacing={2}>
                {jwt ? t('incidentUrlInvalidError.jwt') : t('incidentUrlInvalidError')}
            </Stack>
        );
    }
    if (!incident) {
        return (
            <Stack spacing={2}>
                <LinearProgress/>
            </Stack>
        );
    }

    const updates = incident.updates
        .map(u => new Update(u))
        .sort((a, b) => a.createdDate < b.createdDate ? 1 : -1);

    const lastUpdate = updates[0];

    const isIncident = incident.type === 'incident';
    const subscribed = incident.subscribers?.indexOf(subscriber?.email) >= 0;
    
    const onDelete = () => {
        api._delete(`/api/incidents/${incident.id}`).then(() => {
            enqueueSnackbar(t(isIncident ? 'incidentDeleted' : 'announcementDeleted'));
            navigate('/incidents');
        });
    };

    return (
        <Container maxWidth="md">
            <AlertDialog
                width="md"
                title={t('sendUpdate')}
                text={t('areYouSure?')}
                open={!!askSendUpdate}
                onConfirm={() => sendUpdate(askSendUpdate)}
                onClose={() => setAskSendUpdate(null)}
            />
            {!!newUpdate && loggedIn && (
                <NewUpdateDialog
                    incident={incident}
                    components={components}
                    update={newUpdate}
                    onClose={() => {
                        setNewUpdate(null);
                        refreshIncident();
                        refreshComponents();
                    }}
                />
            )}
            {!!edit && (
                <EditSubscriberDialog
                    components={components}
                    subscriber={subscriber}
                    onClose={() => {
                        setEdit(false);
                        setIncident(false);
                        refreshIncident();
                    }}
                />
            )}
            <AlertDialog
                title={isIncident ? t('deleteIncident') : t('deleteAnnouncement')}
                text={t('deleteIncident.explanationWarning')}
                open={askDelete}
                onConfirm={() => onDelete()}
                onClose={() => setAskDelete(false)}
            />
            <Stack spacing={2}>
                <Stack spacing={2} direction="row" alignItems="center">
                    <h3>{incident.name}</h3>
                    {!subscriber && (
                        <Tooltip title={t('delete')}>
                        <span>
                            <IconButton
                                onClick={() => setAskDelete(true)}
                                size="small"
                            ><TrashIcon/></IconButton>
                        </span>
                        </Tooltip>
                    )}
                    {!!subscriber &&  (
                        <>
                            <Tooltip title={subscribed ? t('unsubscribe') : t('subscribe')}>
                                <span>
                                    <Button
                                        size="small"
                                        disabled={subscriber.isTest}
                                        onClick={() => {
                                            setIncident(null);
                                            api.put('/api/subscribers/incident', {
                                                incidentId: incident.id,
                                                subscribed: !subscribed,
                                                email: subscriber.email,
                                                token
                                            }).then(() => {
                                                refreshIncident();
                                            });
                                        }}
                                        color="success"
                                        sx={{
                                            filter: subscribed ? 'grayscale(1)' : undefined,
                                            minWidth: 40
                                        }}
                                        variant={subscribed ? 'outlined' : 'contained'}
                                    >
                                        {subscribed ? (
                                            <NotificationsOffIcon />
                                        ) : (
                                            <NotificationsActiveIcon />
                                        )}
                                    </Button>
                                </span>
                            </Tooltip>
                            <Button
                                size="small"
                                disabled={subscriber.isTest}
                                onClick={() => setEdit(true)}
                                variant="text"
                            >
                                {t('manageSubscriptions')}
                            </Button>
                        </>
                    )}
                </Stack>
                <Stack spacing={2} direction="row" alignItems="center">
                    <small>{t('created')}:</small>
                    <span>{dayjs(incident.opened).format("DD-MM-YYYY HH:mm")}</span>
                </Stack>
                <Stack spacing={2} direction="row" alignItems="center">
                    <small>{t('lastUpdate')}:</small>
                    <span>{dayjs(lastUpdate.created).format("DD-MM-YYYY HH:mm")}</span>
                </Stack>
                <Stack spacing={2} direction="row" alignItems="center">
                    <small>{t('status')}:</small>
                    <span><StatusChip incident={incident} /></span>
                </Stack>
                <Stack spacing={2} direction="row" alignItems="center">
                    <small>{t('impact')}: </small>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <ImpactAvatar impact={incident.impact}/>
                        <span>{t(impactName[incident.impact])}</span>
                    </Stack>
                </Stack>
                <Stack spacing={2} direction="row" alignItems="center">
                    <small>{t('affectsComponents')}: </small>
                    <Stack spacing={1} direction="row" alignItems="center">
                        {!!components?.length && incident.components.map(id => {
                            const c = components.find(c => c.id === id);
                            return <ComponentChip key={id} color={c.color} name={c.name}/>;
                        })}
                    </Stack>
                </Stack>
                <hr/>
                <Stack spacing={2}>
                    <Stack direction="row">
                        <small style={{flex: 1}}>{t('history')}:</small>
                        {loggedIn && (
                            <Button
                                variant="outlined"
                                onClick={() => setNewUpdate(new Update())}
                                endIcon={<AddIcon/>}>
                                {t('newUpdate')}
                            </Button>
                        )}
                    </Stack>
                    {updates.map((u, i) => (
                        <Paper key={u.created} elevation={2}>
                            <Box padding={2}>
                                <Stack spacing={2} direction="row" alignItems="flex-end">
                                    <Stack spacing={2} flex={1}>
                                        <Stack spacing={2} direction="row" alignItems="center">
                                            <small>{t('date')}:</small>
                                            <span>{u.createdDate.format('DD-MM-YYYY HH:mm')}</span>
                                        </Stack>
                                        <Stack spacing={2} direction="row" alignItems="center">
                                            <small>{t('sent')}:</small>
                                            <span>{u.sent ? u.sentDate.format('DD-MM-YYYY HH:mm') : '-'}</span>
                                        </Stack>
                                        <Stack spacing={2} direction="row" alignItems="center">
                                            <small>{t('currentStatus')}:</small>
                                            <StatusChip incident={incident} status={u.status}/>
                                        </Stack>
                                        <Stack spacing={2} direction="row" alignItems="center">
                                            <small>{t('message')}:</small>
                                        </Stack>
                                        <span style={{whiteSpace: 'pre-wrap'}}>{u.message}</span>
                                    </Stack>
                                    {!subscriber && i === 0 && (
                                        <Stack spacing={2} direction="row">
                                            <Button
                                                variant="outlined"
                                                onClick={() => setNewUpdate(u)}>
                                                {t('edit')}
                                            </Button>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => {
                                                        const email = prompt(t('enterYourEmailToSend'));
                                                        if(!email) return;
                                                        sendUpdate(u.id, email);
                                                    }}>
                                                    {u.sent ? t('sendToEmail') : t('test')}
                                                </Button>
                                            {!u.sent && (
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => setAskSendUpdate(u.id)}>
                                                    {t('send')}
                                                </Button>
                                            )}
                                        </Stack>
                                    )}
                                </Stack>
                            </Box>
                        </Paper>
                    ))}
                </Stack>
            </Stack>
        </Container>
    );
}

export default IncidentDetailPage;