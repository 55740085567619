import React from 'react';
import {Button, LinearProgress, Stack} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import * as api from '../api';
import Box from "@mui/material/Box";
import IncidentSummary from "../components/IncidentSummary";
import EditIncidentDialog from "../components/EditIncidentDialog";
import Incident from '../models/Incident';
import Component from "../models/Component";
import {useTranslation} from "react-i18next";

function IncidentsPage() {
    const [t] = useTranslation();
    let [incidents, setIncidents] = React.useState(null);
    const [components, setComponents] = React.useState(null);
    const [incident, setIncident] = React.useState(null);
    const refresh = () => {
        refreshComponents();
        refreshIncidents();
    }
    const refreshIncidents = () => {
        setIncidents(null);
        api.get('/api/incidents').then(res => {
            setIncidents(res.map(i => new Incident(i)));
        });  
    };
    const refreshComponents = () => {
        setComponents(null);
        api.get('/api/components').then(res => {
            setComponents(res.map(i => new Component(i)));
        });
    };
    React.useEffect(() => {
        refreshIncidents();
        refreshComponents();
    }, []);

    const openIncidents = incidents?.filter(i => i.isOpen).sort((a,b) => a.lastUpdate?.createdDate < b.lastUpdate?.createdDate ? 1 : -1);
    const closedIncidents = incidents?.filter(i => !i.isOpen).sort((a,b) => a.lastUpdate?.createdDate < b.lastUpdate?.createdDate ? 1 : -1);
    
    return (
        <Stack spacing={2}>
            <Box>
                <Button endIcon={<AddIcon />} variant="contained" onClick={() => setIncident(new Incident())}>
                    {t('newIncidentOrAnnouncement')}
                </Button>
            </Box>
            {!!incident && (
                <EditIncidentDialog 
                    incident={incident}
                    components={components}
                    onClose={() => {
                        setIncident(null);
                        refresh();
                    }}
                />
            )}
            {!incidents && <LinearProgress />}
            {!!incidents && (
                <>
                    {openIncidents?.length ? openIncidents.map(i => <IncidentSummary key={i.id} incident={i} />) : (
                        <i>{t('noOpenIncidentsOrAnnouncements')}</i>
                    )}
                    <br />
                    <hr />
                    <br />
                    {closedIncidents?.length ? closedIncidents.map(i => <IncidentSummary key={i.id} incident={i} />) : (
                        <i>{t('noClosedIncidentsOrAnnouncements')}</i>
                    )}                
                </>
            )}
        </Stack>
    );
}

export default IncidentsPage;