import {Record} from 'immutable';

const SubscriberRecord = Record({
    email: '',
    isTest: false,
    components: []
});

export default class Subscriber extends SubscriberRecord {
    email;
    isTest;
    components;
}