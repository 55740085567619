import React from 'react';
import {
    Autocomplete,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    Stack, ToggleButton, ToggleButtonGroup
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ComponentChip from "./ComponentChip";
import * as api from "../api";
import {status, impact, impactIcon} from '../models/Incident';
import {useTranslation} from "react-i18next";

const LowImpactIcon = impactIcon[impact.low];
const MediumImpactIcon = impactIcon[impact.medium];
const HighImpactIcon = impactIcon[impact.high];

const NewUpdateDialog = (props) => {
    const [t] = useTranslation();
    
    const {
        onClose,
        incident,
        components,
        update
    } = props;
    
    const [message, setMessage] = React.useState(update?.message || '');
    const [newStatus, setStatus] = React.useState(incident.status);
    const [newImpact, setImpact] = React.useState(incident.impact);
    const [newComponents, setComponents] = React.useState(incident.components);
    const [date, setDate] = React.useState(update?.id ? update.createdDate : dayjs());
    const [loading, setLoading] = React.useState(false);

    const canSave = !!message;
    
    const save = send => {
        setLoading(true);
        api.put('/api/incidents', {
            updateId: update?.id,
            incidentId: incident.id,
            date,
            message,
            newStatus,
            newImpact,
            newComponents,
            send
        }).then(() => {
            setLoading(false);
            onClose();
        });
    }

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={true}
        >
            <DialogTitle>{t('newUpdate')}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <MobileDateTimePicker
                        label={t('date')}
                        ampm={false}
                        disableMaskedInput
                        disabled={loading}
                        minutesStep={5}
                        inputFormat="DD-MM-YYYY HH:mm"
                        value={date || dayjs()}
                        onChange={e => setDate(e)}
                        renderInput={(params) => <TextField {...params} />}
                    />

                    <div>
                        <InputLabel>{t('newImpact')}</InputLabel>
                        <ToggleButtonGroup
                            exclusive
                            value={newImpact || impact.low}
                            onChange={(_,impact) => setImpact(impact)}
                        >
                            <ToggleButton color="info" value={impact.low}>{t('impact.low')} <LowImpactIcon /></ToggleButton>
                            <ToggleButton color="warning" value={impact.medium}>{t('impact.medium')} <MediumImpactIcon /></ToggleButton>
                            <ToggleButton color="error" value={impact.high}>{t('impact.high')} <HighImpactIcon /></ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div>
                        <InputLabel>{t('newStatus')}</InputLabel>
                        {incident?.type === 'incident' && (
                            <ToggleButtonGroup
                                exclusive
                                value={newStatus}
                                onChange={(_,s) => setStatus(s)}
                            >
                                <ToggleButton color="error" value={status.detected}>{t('status.detected')}</ToggleButton>
                                <ToggleButton color="warning" value={status.investigating}>{t('status.investigating')}</ToggleButton>
                                <ToggleButton color="info" value={status.monitoring}>{t('status.monitoring')}</ToggleButton>
                                <ToggleButton color="success" value={status.resolved}>{t('status.resolved')}</ToggleButton>
                            </ToggleButtonGroup>
                        )}
                        {incident?.type === 'announcement' && (
                            <ToggleButtonGroup
                                exclusive
                                value={newStatus}
                                onChange={(_,s) => setStatus(s)}
                            >
                                <ToggleButton color="info" value={status.detected}>{t('status.open')}</ToggleButton>
                                <ToggleButton color="success" value={status.resolved}>{t('status.closed')}</ToggleButton>
                            </ToggleButtonGroup>
                        )}
                    </div>
                    <Autocomplete
                        multiple
                        openOnFocus
                        disabled={loading}
                        disableCloseOnSelect
                        filterSelectedOptions
                        value={newComponents || []}
                        isOptionEqualToValue={(o, v) => o?.id === v}
                        onChange={(_, values) => setComponents(values.map(c => c?.id || c))}
                        getOptionLabel={c => c.name}
                        renderOption={(liProps, o) => (
                            <li {...liProps}><ComponentChip key={o?.id} color={o?.color} name={o?.name} style={{margin: 5}} /></li>
                        )}
                        renderTags={(coms, getTagProps) => {
                            return coms.map((c, index) => {
                                const com = components.find(co => co?.id === c);
                                return (
                                    <ComponentChip
                                        name={com.name}
                                        color={com.color}
                                        key={c?.id || c}
                                        {...getTagProps({ index })}
                                    />
                                );
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={t('components')}
                            />
                        )}
                        options={components}
                    />
                    <TextField
                        multiline
                        disabled={loading}
                        label={t('message')}
                        minRows={4}
                        value={message}
                        onChange={e => setMessage(e.currentTarget.value)}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row">
                    <Button
                        disabled={loading}
                        onClick={props.onClose}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        variant={update?.sent ? 'contained' : 'outlined'}
                        endIcon={!!loading && <CircularProgress size={16} />}
                        style={{margin: '0 10px'}}
                        disabled={!canSave || loading}
                        onClick={() => save(false)}
                    >
                        {t('save')}
                    </Button>
                    {!update?.sent && (
                        <Button
                            variant="contained"
                            endIcon={!!loading && <CircularProgress size={16} />}
                            disabled={!canSave || loading}
                            onClick={() => save(true)}
                        >
                            {t('saveAndSend')}
                        </Button>
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    )

};

export default NewUpdateDialog;