import React from 'react';
import {IconButton, Paper, Stack, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import ComponentChip from "./ComponentChip";
import AddIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/RemoveCircleOutline';
import TrashIcon from '@mui/icons-material/Delete';
import SubscribeAllIcon from '@mui/icons-material/BookmarkAdded';
import UnsubscribeAllIcon from '@mui/icons-material/BookmarkRemove';
import {Colors, isDark} from "../colors";
import AlertDialog from "./AlertDialog";
import {useTranslation} from "react-i18next";

const SubscriberSummary = ({subscriber, components, onChange, onEdit, onDelete, onSubscribeAll}) => {
    const [t] = useTranslation();
    const [askDelete, setAskDelete] = React.useState(false);

    return (<Paper>
        <AlertDialog
            title={t('deleteSubscriber')}
            text={t('deleteSubscriber.confirm', subscriber)}
            open={askDelete}
            onConfirm={onDelete}
            onClose={() => setAskDelete(false)}
        />
        <Box padding={2}>
            <Stack spacing={2}>
                <Stack spacing={1} direction="row" alignItems="center">
                    <span>{subscriber.email}</span>
                    <Tooltip title={t('unsubscribeAllComponents')}>
                            <span>
                                <IconButton
                                    onClick={() => onSubscribeAll(false)}
                                    size="small"
                                ><UnsubscribeAllIcon/></IconButton>
                            </span>
                    </Tooltip>
                    <Tooltip title={t('subscribeAllComponents')}>
                        <span>
                            <IconButton
                                onClick={() => onSubscribeAll(true)}
                                size="small"
                            ><SubscribeAllIcon/></IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={t('deleteSubscriber')}>
                        <span>
                            <IconButton
                                onClick={() => setAskDelete(true)}
                                size="small"
                            ><TrashIcon/></IconButton>
                        </span>
                    </Tooltip>
                </Stack>
                <Stack direction="row" spacing={2}>
                    {components?.map(c => {
                        const subscribed = subscriber.components.indexOf(c?.id) >= 0;
                        return (<ComponentChip
                            key={c?.id}
                            name={c?.name}
                            color={c?.color}
                            variant={subscribed ? undefined : 'outlined'}
                            icon={subscribed ? <DeleteIcon sx={{
                                fill: isDark(c?.color) ? Colors.white : undefined,
                            }}/> : <AddIcon sx={{
                                fill: c?.color
                            }}/>}
                            onClick={() => onChange(c.id, !subscribed)}
                        />);
                    })}
                </Stack>
            </Stack>
        </Box>
    </Paper>);

};

export default SubscriberSummary;