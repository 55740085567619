import React from 'react';
import TextField from '@mui/material/TextField'
import {Button, Grid} from "@mui/material";
import * as api from '../api';
import {useTranslation} from "react-i18next";

function LoginPage() {
    const [t] = useTranslation();
    const [user, setUser] = React.useState('');
    const [pass, setPass] = React.useState('');
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        setError(false);
    }, [user, pass, setError]);
    
    const login = () => {
        setError(false);
        api.post(
            '/api/login',
            {user, pass}
        ).then(res => {
            if (!res) return setError(true);
            localStorage.setItem('jwt', res);
            window.location.href = '/incidents'; 
        });
    };
    const loginOnEnter = e => e.key === 'Enter' && login();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label={t('userName')}
                    error={error}
                    value={user}
                    onKeyDown={loginOnEnter}
                    onChange={e => setUser(e.currentTarget.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="password"
                    label={t('password')}
                    error={error}
                    value={pass}
                    onKeyDown={loginOnEnter}
                    onChange={e => setPass(e.currentTarget.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <Button onClick={login}>{t('login')}</Button>
            </Grid>
        </Grid>
    );
}

export default LoginPage;