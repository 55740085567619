import React from 'react';
import {
    Autocomplete,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    Stack, ToggleButton, ToggleButtonGroup
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ComponentChip from "./ComponentChip";
import * as api from "../api";
import {status, impact, impactIcon} from '../models/Incident';
import {useTranslation} from "react-i18next";

const LowImpactIcon = impactIcon[impact.low];
const MediumImpactIcon = impactIcon[impact.medium];
const HighImpactIcon = impactIcon[impact.high];

const EditIncidentDialog = (props) => {
    const [t] = useTranslation();
    const [incident, setIncident] = React.useState(null);
    const [firstUpdate, setFirstUpdate] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (!props.incident) return;
        setIncident(props.incident.set('_', ''));
    }, [props.incident]);

    const set = (field, value) => {
        setIncident(incident.set(field, value));
    };
    console.log(incident?.toJS().status);

    const canSave = (() => {
        if (!incident?.name) return false;
        if (!incident?.components?.length) return false;
        if (!firstUpdate) return false;
        return true;
    })();
    const save = send => {
        setLoading(true);
        debugger;
        api.post('/api/incidents', {
            incident: incident.toJS(),
            send,
            firstUpdate
        }).then(() => {
            setLoading(false);
            props.onClose();
        });
    }

    let title = '';
    if (incident?.id) title = incident.type === 'announcement' ? t('editAnnouncement') : t('editIncident');
    else title = incident?.type === 'announcement' ? t('newAnnouncement') : t('newIncident');

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={!!props.incident}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>

                    {!incident?.id && (
                        <div>
                            <InputLabel>{t('type')}</InputLabel>
                            <ToggleButtonGroup
                                exclusive
                                value={incident?.type}
                                onChange={(_, type) => {
                                    set('status', 0)
                                    set('type', type);
                                }}
                            >
                                <ToggleButton color="info" value="announcement">{t('announcement')}</ToggleButton>
                                <ToggleButton color="warning" value="incident">{t('incident')}</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    )}
                    <TextField
                        fullWidth
                        disabled={loading}
                        label={t('name')}
                        value={incident?.name || ''}
                        onChange={e => set('name', e.currentTarget.value)}
                    />
                    <MobileDateTimePicker
                        label={t('startDate')}
                        ampm={false}
                        disableMaskedInput
                        disabled={loading}
                        minutesStep={5}
                        inputFormat="DD-MM-YYYY HH:mm"
                        value={incident?.opened || dayjs()}
                        onChange={e => set('opened', e)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <div>
                        <InputLabel>{t('impact')}</InputLabel>
                        <ToggleButtonGroup
                            exclusive
                            value={incident?.impact || impact.low}
                            onChange={(_, impact) => set('impact', impact)}
                        >
                            <ToggleButton color="info" value={impact.low}>{t('impact.low')}
                                <LowImpactIcon/></ToggleButton>
                            <ToggleButton color="warning" value={impact.medium}>{t('impact.medium')} <MediumImpactIcon/></ToggleButton>
                            <ToggleButton color="error" value={impact.high}>{t('high')} <HighImpactIcon/></ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div>
                        <InputLabel>{t('status')}</InputLabel>
                        {incident?.type === 'incident' && (
                            <ToggleButtonGroup
                                exclusive
                                value={incident?.status || status.detected}
                                onChange={(_, status) => set('status', status)}
                            >
                                <ToggleButton color="error"
                                              value={status.detected}>{t('status.detected')}</ToggleButton>
                                <ToggleButton color="warning"
                                              value={status.investigating}>{t('status.investigating')}</ToggleButton>
                                <ToggleButton color="info"
                                              value={status.monitoring}>{t('status.monitoring')}</ToggleButton>
                                <ToggleButton color="success"
                                              value={status.resolved}>{t('status.resolved')}</ToggleButton>
                            </ToggleButtonGroup>
                        )}
                        {incident?.type === 'announcement' && (
                            <ToggleButtonGroup
                                exclusive
                                value={incident?.status || status.detected}
                                onChange={(_, status) => set('status', status)}
                            >
                                <ToggleButton color="info" value={status.detected}>{t('status.open')}</ToggleButton>
                                <ToggleButton color="success"
                                              value={status.resolved}>{t('status.closed')}</ToggleButton>
                            </ToggleButtonGroup>
                        )}
                    </div>
                    <Autocomplete
                        multiple
                        openOnFocus
                        disabled={loading}
                        disableCloseOnSelect
                        filterSelectedOptions
                        value={incident?.components || []}
                        isOptionEqualToValue={(o, v) => o?.id === v}
                        onChange={(_, values) => set('components', values.map(c => c?.id || c))}
                        getOptionLabel={c => c.name}
                        renderOption={(liProps, o) => (
                            <li {...liProps}><ComponentChip key={o?.id} color={o?.color} name={o?.name}
                                                            style={{margin: 5}}/></li>
                        )}
                        renderTags={(coms, getTagProps) => {
                            return coms.map((c, index) => {
                                const com = props.components.find(co => co?.id === c);
                                return (
                                    <ComponentChip
                                        name={com.name}
                                        color={com.color}
                                        key={c?.id || c}
                                        {...getTagProps({index})}
                                    />
                                );
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={t('components')}
                            />
                        )}
                        options={props.components}
                    />
                    {!incident?.id && (
                        <TextField
                            multiline
                            disabled={loading}
                            label={t('initialUpdate')}
                            minRows={4}
                            value={firstUpdate}
                            onChange={e => setFirstUpdate(e.currentTarget.value)}
                        />
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row">
                    <Button
                        disabled={loading}
                        onClick={props.onClose}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        variant="outlined"
                        endIcon={!!loading && <CircularProgress size={16}/>}
                        style={{margin: '0 10px'}}
                        disabled={!canSave || loading}
                        onClick={() => save(false)}
                    >
                        {t('save')}
                    </Button>
                    {}
                    <Button
                        variant="contained"
                        endIcon={!!loading && <CircularProgress size={16}/>}
                        disabled={!canSave || loading}
                        onClick={() => save(true)}
                    >
                        {t('saveAndSend')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )

};

export default EditIncidentDialog;