import React from 'react';
import {Chip} from "@mui/material";
import {Colors, darken, isDark} from "../colors";

const ComponentChip = ({name, color, ...other}) => {
    
    return (
        <Chip
            label={name}
            {...other}
            sx={{
                '&.MuiChip-filled': {
                    backgroundColor: color,
                    color: isDark(color) ? Colors.white : undefined
                },
                '&.MuiChip-filled:hover': {
                    backgroundColor: `${darken(color, 1.2)}`
                },
                '&.MuiChip-outlined': {
                    borderColor: color
                },
                '&.MuiChip-outlined:hover': {
                    backgroundColor: Colors.gray20
                },
                ...other.sx
            }}
        />
    );
    
};

export default ComponentChip;