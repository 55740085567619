import {createTheme} from "@mui/material";

export default createTheme({
    palette: {
        primary: {
            main: '#1f3e79',
        },
        secondary: {
            main: '#e4022e',
        },
    },
    components: {
        MuiDialogContent: {
            defaultProps: {
                dividers: true
            }
        }
    }
});