import {Record} from 'immutable';
import {Colors} from "../colors";

const ComponentRecord = Record({
    id: 0,
    name: '',
    uuid: '',
    color: Colors.gray30,
    subscribers: [],
    incidents: [],
    userTypes: []
});

export default class Component extends ComponentRecord {
    id;
    name;
    uuid;
    color;
    subscribers;
    incidents;
    userTypes;
}