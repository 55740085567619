import {Tooltip} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React from "react";
import {impactColor, impactIcon, impactName} from "../models/Incident";
import {useTranslation} from "react-i18next";

const ImpactAvatar = ({impact}) => {
    const [t] = useTranslation();
    const Icon = impactIcon[impact];
    return (
        <Tooltip title={`${t('impact')}: ${t(impactName[impact])}`} placement="right">
            <Avatar
                sx={{ 
                    width: 24,
                    height: 24,
                    backgroundColor: impactColor[impact]
                }} 
                variant="rounded"><Icon /></Avatar>
        </Tooltip>
    );
};

export default ImpactAvatar;