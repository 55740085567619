import React, {Component} from 'react';
import NavMenu from './NavMenu';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

export class Layout extends Component {

    render() {
        return (
            <div>
                <NavMenu/>
                <Container>
                    <Box paddingTop={2}>
                        {this.props.children}
                    </Box>
                </Container>
            </div>
        );
    }
}
