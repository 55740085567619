import React from 'react';
import {Button, LinearProgress, Stack} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import * as api from '../api';
import Box from "@mui/material/Box";
import ComponentSummary from "../components/ComponentSummary";
import EditComponentDialog from "../components/EditComponentDialog";
import Component from '../models/Component';
import {useTranslation} from "react-i18next";
import AlertDialog from "../components/AlertDialog";

function ComponentsPage() {
    const [t] = useTranslation();
    const [askDelete, setAskDelete] = React.useState(null);
    const [components, setComponents] = React.useState(null);
    const [component, setComponent] = React.useState(null);
    
    const refreshComponents = () => {
        setComponents(null);
        api.get('/api/components').then(setComponents);
    };
    React.useEffect(() => {
        refreshComponents();
    }, []);
    
    return (
        <Stack spacing={2}>
            <AlertDialog
                width="md"
                title={t('deleteComponent')}
                text={t('areYouSure?')}
                open={!!askDelete}
                onConfirm={() => {
                    setAskDelete(null);
                    api._delete(`/api/components/${askDelete}`).then(() => {
                        refreshComponents();
                    });
                }}
                onClose={() => setAskDelete(null)}
            />
            <Box>
                <Button endIcon={<AddIcon />} variant="contained" onClick={() => setComponent(new Component())}>
                    {t('newComponent')}
                </Button>
            </Box>
            {!!component && (
                <EditComponentDialog 
                    component={component}
                    onClose={() => {
                        setComponent(null);
                        refreshComponents();
                    }} />
            )}
            {components === null ? (
                <LinearProgress />
            ) : components.length ? components.map(i => (
                <ComponentSummary 
                    key={i.id}
                    component={i}
                    onRequestUpdate={() => setComponent(new Component(i))}
                    onRequestDelete={() => setAskDelete(i.id)}
                />
            )) : (
                <i>{t('noComponentsFound')}</i>
            )}
        </Stack>
    );
}

export default ComponentsPage;