import React from 'react';

import {Button, Card, CardActions, CardContent, Stack} from "@mui/material";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import StatusChip from "./StatusChip";
import ImpactAvatar from "./ImpactAvatar";
import {useTranslation} from "react-i18next";


const IncidentSummary = ({incident}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    
    const lastUpdate = incident.updates[incident.updates.length - 1];
    
    return (
        <Card sx={{cursor: 'pointer'}} onClick={() => navigate(`/incidents/${incident.uuid}`)}>
            <CardContent>
                <Stack spacing={2} direction="row" alignItems="center">
                    <ImpactAvatar impact={incident.impact} />
                    <span>{incident.name}</span>
                    <StatusChip incident={incident} />
                    <Stack flex={1} alignItems="flex-end">
                        <Stack flex={1} justifyContent="flex-end" alignItems="center" direction="row">
                            <small>{t('created')}: &nbsp;</small>
                            <span>{dayjs(incident.opened).format("DD-MM-YYYY HH:mm")}</span>
                        </Stack>
                        <Stack flex={1} justifyContent="flex-end" alignItems="center" direction="row">
                            <small>{t('lastUpdate')}:&nbsp;</small>
                            <span>{dayjs(lastUpdate.created).format("DD-MM-YYYY HH:mm")}</span>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
            <CardActions>
                <Button onClick={() => navigate(`/incidents/${incident.uuid}`)}>
                    {t('manage')}
                </Button>
            </CardActions>
        </Card>
    )
    
};

export default IncidentSummary;