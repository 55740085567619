import React from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {Layout} from './components/Layout';
import jwt_decode from "jwt-decode";

import './custom.css'

import LoginPage from "./pages/LoginPage";
import HomePage from './pages/HomePage';
import IncidentsPage from "./pages/IncidentsPage";
import dayjs from "dayjs";
import {ThemeProvider} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import ComponentsPage from "./pages/ComponentsPage";
import IncidentDetailPage from "./pages/IncidentDetailPage";
import SubscribersPage from "./pages/SubscribersPage";
import {SnackbarProvider} from "notistack";
import PreferencesPage from "./pages/PreferencesPage";

export const logout = (redirect) => {
    localStorage.removeItem('jwt');
    window.location.href = redirect || '/login';
}

const theme = require('./theme').default;

export default function App() {

    const jwt = localStorage.getItem('jwt');
    if (jwt) {
        const decoded = jwt_decode(jwt);
        if (decoded.exp < dayjs().unix()) {
            logout();
            return null;
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarProvider maxSnack={3}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <Layout>
                            <Routes>
                                <Route exact path='/login' element={<LoginPage/>}/>
                                {!!jwt && <Route path='/incidents' element={<IncidentsPage/>}/>}
                                {!!jwt && <Route path='/incidents/:uuid' element={<IncidentDetailPage/>}/>}
                                {!jwt && <Route path='/incidents/:token' element={<IncidentDetailPage/>}/>}
                                {!!jwt && <Route path='/components' element={<ComponentsPage/>}/>}
                                {!!jwt && <Route path='/subscribers' element={<SubscribersPage/>}/>}
                                {!!jwt && <Route path='/preferences' element={<PreferencesPage/>}/>}
                                <Route path='/:page' element={<HomePage/>}/>
                                <Route path='/' element={<HomePage/>}/>
                            </Routes>
                        </Layout>
                    </BrowserRouter>
                </ThemeProvider>
            </SnackbarProvider>
        </LocalizationProvider>
    );
}
