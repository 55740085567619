import React from 'react';
import {Button, LinearProgress, Stack} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import * as api from '../api';
import SubscriberSummary from "../components/SubscriberSummary";
import Subscriber from '../models/Subscriber';
import Component from "../models/Component";
import TextField from "@mui/material/TextField";
import EditSubscriberDialog from "../components/EditSubscriberDialog";
import {useTranslation} from "react-i18next";

function SubscribersPage() {
    const [t] = useTranslation();
    const [subscribers, setSubscribers] = React.useState(null);
    const [subscriber, setSubscriber] = React.useState(null);
    const [components, setComponents] = React.useState([]);
    const [filter, setFilter] = React.useState('');

    const refreshSubscribers = React.useCallback(() => {
        setSubscribers(null);
        api.get('/api/subscribers').then(setSubscribers);
    }, [setSubscribers]);
    const refreshComponents = React.useCallback(() => {
        setComponents(null);
        api.get('/api/components').then(res => {
            setComponents(res.map(i => new Component(i)));
        });
    }, [setComponents]);
    React.useEffect(() => {
        refreshComponents();
        refreshSubscribers();
    }, [refreshComponents, refreshSubscribers]);

    return (
        <Stack spacing={2}>
            {!!subscriber && (
                <EditSubscriberDialog
                    fromAdmin
                    subscriber={subscriber}
                    components={components}
                    onClose={() => {
                        setSubscriber(null);
                        refreshSubscribers();
                    }}
                />
            )}
            <Stack spacing={2} direction="row" alignItems="center">
                <Button endIcon={<AddIcon/>} variant="contained" onClick={() => setSubscriber(new Subscriber())}>
                    {t('newSubscriber')}
                </Button>
                <TextField
                    label={t('filter')}
                    value={filter}
                    onChange={e => setFilter(e.currentTarget.value)}
                    type="search"
                    size="small"
                />
            </Stack>
            {subscribers === null ? (
                <LinearProgress/>
            ) : subscribers.length ? subscribers
                    .filter(s => !filter || s.email.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
                    .map((s, i) => (
                <SubscriberSummary
                    key={s.id}
                    subscriber={s}
                    components={components}
                    onEdit={() => setSubscriber(new Subscriber(s))}
                    onDelete={() => {
                        setSubscribers(null);
                        api._delete(`/api/subscribers/${s.email}`).then(refreshSubscribers);
                    }}
                    onSubscribeAll={subscribed => {
                        api.put('/api/subscribers', {
                            email: s.email,
                            components: subscribed ? components?.map(c => c.id) : []
                        }).then(res => {
                            subscribers[i] = res;
                            setSubscribers(subscribers.map(s => s));
                        })
                    }}
                    onChange={(componentId, subscribed) => {
                        api.put('/api/subscribers/component', {
                            subscriberId: s.id,
                            componentId,
                            subscribed
                        }).then(res => {
                            subscribers[i] = res;
                            setSubscribers(subscribers.map(s => s));
                        })
                    }}
                />
            )) : (
                <i>{t('noSubscribers')}</i>
            )}
        </Stack>
    );
}

export default SubscribersPage;