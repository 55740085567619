export const get = async (url, headers) => {
    return await request(url, 'GET', null, headers);
}
export const post = async (url, body, headers) => {
    return await request(url, 'POST', body, headers);
}
export const put = async (url, body, headers) => {
    return await request(url, 'PUT', body, headers);
}
export const _delete = async (url, body, headers) => {
    return await request(url, 'DELETE', body, headers);
}
export const request = async (url, method, body, headers) => {
    if(!headers) headers = {};

    const jwt = localStorage.getItem('jwt');
    if(jwt && !headers.authorization) {
        headers.authorization = jwt;
    }
    const options = {method, headers};
    if(body) options.body = JSON.stringify(body);
    url = `${process.env.REACT_APP_API_URL || ''}${url}`;
    const res = await fetch(url, options);
    if(res.ok) {
        const newToken = res.headers.get('token');
        if(newToken) localStorage.setItem('jwt', newToken);
        console.debug('new token saved');
    }
    const json = await res.text();
    if(json) return JSON.parse(json);
    return json;
}