import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import {Button, Stack} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {logout} from "../App";
import {useTranslation} from "react-i18next";

const NavMenu = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const {pathname} = useLocation();

    const user = localStorage.getItem('jwt');
    return (
        <header>
            <Box sx={{flexGrow: 1}}>
                <AppBar component="nav" position="static" color="default">
                    <Container maxWidth="xl">
                        <Toolbar>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'row', width: 300}}>
                                <img src="/images/logo.svg" style={{maxWidth: 100, marginRight: 10}} alt="Logo"/>
                                <span style={{cursor: 'pointer'}} onClick={() => navigate('/')}>{t('pageTitle')}</span>
                            </div>
                            {user && (
                                <Stack direction="row" spacing={1}>
                                    <Button onClick={() => navigate("/incidents")}>{t('incidents')}</Button>
                                    <Button onClick={() => navigate("/components")}>{t('components')}</Button>
                                    <Button onClick={() => navigate("/subscribers")}>{t('subscribers')}</Button>
                                    <Button onClick={() => navigate("/preferences")}>{t('preferences')}</Button>
                                    <Button variant="outlined" onClick={() => logout()}>{t('logout')}</Button>
                                </Stack>
                            )}
                            {!user && pathname.indexOf('/incidents') !== 0 && (
                                <Button onClick={() => navigate("/login")}>{t('login')}</Button>
                            )}
                        </Toolbar>
                    </Container>
                </AppBar>
            </Box>
        </header>
    );
}

export default NavMenu;
