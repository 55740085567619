import {Chip} from "@mui/material";
import {Colors} from "../colors";
import React from "react";
import {statusColor, statusColorAnnouncement, statusName, statusNameAnnouncement} from "../models/Incident";
import {useTranslation} from "react-i18next";

const StatusChip = ({incident,status}) => {
    const [t] = useTranslation();
    const isIncident = incident.type === 'incident';
    if(!status) status = incident.status;
    
    const bgcolor= isIncident ? statusColor[status] : statusColorAnnouncement[status];
    return (
        <Chip
            sx={{bgcolor, color: Colors.white}}
            label={t(isIncident ? statusName[status] : statusNameAnnouncement[status])}
        />
    );
}

export default StatusChip;