
export class Colors {
    static transparent = '#FFFFFFFF';

    static green = '#7AC943';
    static lightGreen = '#C5E7AC';
    static yellow = '#F3AD3B';
    static lightYellow = '#f3e69e';
    static orange = '#ffa000';
    static blue = '#497ac5';
    static lightBlue = '#D6E1F2';
    static lightRed = '#dc8492';

    static white = '#ffffff';
    static black = '#17191c';

    static gray05 = '#f8f8f8'; //248
    static gray10 = '#F2F2F2'; //242
    static gray20 = '#ebebeb'; //235
    static gray30 = '#BFBFBF'; //191
    static gray40 =  '#999999'; //153
    static gray50 = '#808080'; //128
    static gray60 = '#393E44'; //~62

    static info = '#497ac5';
    static success = '#28a745';
    static warning = '#ffc107';
    static danger = '#dc3545';
}

export function toRGB(hex) {
    hex = hex.substring(0, 7);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
}

export function toHex(rgb) {
    return '#' + rgb.map(c => c.toString(16).padStart(2, 0)).join('');
}

export function alpha(hex, a) {
    const rgba = toRGB(hex);
    rgba.push(a);
    return `rgba(${rgba.join(',')})`;
}

export function contrastColor(backgroundColorHex, darkColor = Colors.gray60, lightColor = Colors.white) {
    return isDark(backgroundColorHex) ? lightColor : darkColor;
}

export function isDark(color, threshold = 0.3) {
    if(!color) return false;
    const [r, g, b] = toRGB(color);
    const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a > threshold;
}

export function lighten(color, percentage, isHex = true) {
    const rgb = isHex ? toRGB(color) : color;
    return toHex(rgb.map(r => Math.abs(Math.min(255, Math.round(r * percentage)))));
}
export function darken(color, percentage, isHex = true) {
    return lighten(color, 1/percentage, isHex);
}

export function noHash(clr) {
    return clr.replace('#', '');
}
