import React from 'react';
import {
    Autocomplete,
    Button,
    Chip,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip
} from "@mui/material";
import {useTranslation} from "react-i18next";
import * as api from '../api';
import ComponentChip from "../components/ComponentChip";
import TextField from "@mui/material/TextField";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Clear';
import {useSnackbar} from "notistack";

const userTypes = {
    3: {
        name: 'admin',
        color: '#78ffe8'
    },
    4: {
        name: 'portalUser',
        color: '#bcff95'
    },
    11: {
        name: 'appOnly',
        color: '#ff7f8b'
    },
    8: {
        name: 'arcUser',
        color: '#fff0b3'
    },
    6: {
        name: 'accountManager',
        color: '#adc8ff'
    },
}

function PreferencesPage() {
    const [t] = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const [editComponent, setEditComponent] = React.useState();
    const [components, setComponents] = React.useState();

    const refresh = () => {
        api.get('/api/components').then(setComponents);
    }
    const set = (component, userTypes) => {
        const newComponents = [...components];
        const index = newComponents.indexOf(component);
        newComponents[index].userTypes = userTypes;
        setComponents(newComponents);
    }
    React.useEffect(() => refresh(), []);

    return (
        <Stack spacing={2}>
            {/*<Stack spacing={2} direction="row" alignItems="center">*/}
            {/*    <Button variant="contained" onClick={() => {*/}
            {/*        api.put('/api/subscribers/autoSubscribe').then();*/}
            {/*        enqueueSnackbar(t('willBeProcessed'));*/}
            {/*    }}>*/}
            {/*        {t('autoSubscribe')}*/}
            {/*    </Button>*/}
            {/*    <small style={{color: '#939393'}}>{t('autoSubscribeExplanation')}</small>*/}
            {/*</Stack>*/}
            {/*<hr />*/}
            <Table>
                <TableBody>
                    {components?.map(c => (
                        <TableRow key={c.id}>
                            <TableCell width={150}>
                                <ComponentChip name={c.name} color={c.color}/>
                            </TableCell>
                            <TableCell>
                                {editComponent === c.id ? (
                                    <Autocomplete
                                        multiple
                                        openOnFocus
                                        disableCloseOnSelect
                                        filterSelectedOptions
                                        style={{width: '100%'}}
                                        value={c.userTypes || []}
                                        noOptionsText={t('noOptions')}
                                        isOptionEqualToValue={(o, v) => +o === +v}
                                        onChange={(e, values) => set(c, values.map(v => +v))}
                                        getOptionLabel={c => t('userType.' + userTypes[c].name)}
                                        renderOption={(liProps, o) => (
                                            <li {...liProps}>
                                                <Chip
                                                    key={o}
                                                    size="small"
                                                    label={t('userType.' + userTypes[o].name)}
                                                    style={{margin: 5, background: userTypes[o].color}}
                                                />
                                            </li>
                                        )}
                                        renderTags={(values, getTagProps) => {
                                            return values.map((o, index) => {
                                                const tagProps = getTagProps({index});
                                                return (
                                                    <Chip
                                                        label={t('userType.' + userTypes[o].name)}
                                                        size="small"
                                                        key={index}
                                                        {...tagProps}
                                                        style={{...tagProps.style, background: userTypes[o].color}}
                                                    />
                                                );
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label={t('defaultSubscribedUserTypes')}
                                            />
                                        )}
                                        options={Object.keys(userTypes)}
                                    />
                                ) : (
                                    <fieldset style={{
                                        borderWidth: 0,
                                        marginTop: -3,
                                        height: 38,
                                        paddingLeft: 0,
                                        marginLeft: -1
                                    }}>
                                        <legend style={{
                                            color: 'rgba(0,0,0,.6)',
                                            fontSize: 12,
                                            marginBottom: 1,
                                            paddingTop: 2
                                        }}>{t('defaultSubscribedUserTypes')}</legend>
                                        <Stack direction="row" spacing={1}>
                                            &nbsp;
                                            {c.userTypes.map(o => (
                                                <Chip
                                                    label={t('userType.' + userTypes[o].name)}
                                                    size="small"
                                                    key={o}
                                                    style={{background: userTypes[o].color, height: 24}}
                                                />
                                            ))}
                                        </Stack>
                                    </fieldset>
                                )}
                            </TableCell>
                            <TableCell width={70}>
                                {editComponent === c.id && (
                                    <Stack direction="row">
                                        <Tooltip title={t('save')}>
                                        <span>
                                            <IconButton onClick={() => {
                                                api.put('/api/components', c).then(() => {
                                                    setEditComponent(null);
                                                    refresh();
                                                });
                                            }}>
                                                <SaveIcon/>
                                            </IconButton>
                                        </span>
                                        </Tooltip>
                                        <Tooltip title={t('cancel')}>
                                        <span>
                                            <IconButton onClick={() => {
                                                setEditComponent(null);
                                                refresh();
                                            }}>
                                                <CancelIcon/>
                                            </IconButton>
                                        </span>
                                        </Tooltip>
                                    </Stack>
                                )}
                                {!editComponent && editComponent !== c.id && (
                                    <Tooltip title={t('edit')}>
                                        <span>
                                            <IconButton onClick={() => setEditComponent(c.id)}>
                                                <EditIcon/>
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Stack>
    );
}

export default PreferencesPage;