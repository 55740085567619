import React from 'react';
import {
    Button, CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Stack
} from "@mui/material";
import {CirclePicker} from 'react-color';
import TextField from "@mui/material/TextField";
import ComponentChip from "./ComponentChip";
import {Colors} from "../colors";
import * as api from '../api';
import {useTranslation} from "react-i18next";

const EditComponentDialog = (props) => {
    const [t] = useTranslation();
    const [component, setComponent] = React.useState(props.component || null);
    const [loading, setLoading] = React.useState(false);
    
    React.useEffect(() => {
        if (!props.component) return;
        setComponent(props.component.set('_', ''));
    }, [props.component]);

    const set = (field, value) => {
        setComponent(component.set(field, value));
    };

    const canSave = (() => {
        if (!component?.name) return false;
        if (!component?.color) return false;
        return true;
    })();

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={!!props.component}>
            <DialogTitle>{component?.id ? t('editComponent') : t('newComponent')}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        disabled={loading}
                        label={t('name')}
                        value={component?.name || ''}
                        onChange={e => set('name', e.currentTarget.value)}
                    />
                    <Stack direction="row" sx={{
                        pointerEvents: loading ? 'none' : undefined,
                        opacity: loading ? .7 : undefined
                    }}>
                        <CirclePicker
                            color={component?.color || Colors.gray30}
                            onChangeComplete={c => set('color', c.hex)}
                        />
                        <Stack flex={1} alignItems="center" display="flex" justifyContent="center">
                            <ComponentChip 
                                color={component?.color || Colors.gray30} 
                                name={component?.name || t('myComponent')} 
                            />
                            <small>{t('preview')}</small>
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} direction="row">
                    <Button onClick={props.onClose} disabled={loading}>
                        {t('cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!canSave || loading}
                        endIcon={!!loading && <CircularProgress size={16} />}
                        onClick={() => {
                            setLoading(true);
                            const action = !!component.id ? api.put : api.post;
                            action('/api/components', component.toJS()).then(() => {
                                setLoading(false);
                                props.onClose();
                            });
                        }}
                    >
                        {t('save')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
)

};

export default EditComponentDialog;