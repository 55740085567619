import React from 'react';
import {
    Autocomplete,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack
} from "@mui/material";
import TextField from "@mui/material/TextField";
import ComponentChip from "./ComponentChip";
import * as api from "../api";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EditSubscriberDialog = (props) => {
    const [t] = useTranslation();
    const [subscriber, setSubscriber] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const {token} = useParams();
    
    React.useEffect(() => {
        if (!props.subscriber) return;
        setSubscriber(props.subscriber.set('_', ''));
    }, [props.subscriber]);

    const set = (field, value) => {
        setSubscriber(subscriber.set(field, value));
    };

    const canSave = (() => {
        if (!subscriber?.email) return false;
        return emailRegex.test(subscriber?.email);
    })();

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={!!props.subscriber}>
            <DialogTitle>{props.subscriber?.email ? t('editSubscriptions') : t('newSubscriber')}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        disabled={!props.fromAdmin || loading}
                        type="email"
                        label={t('email')}
                        value={subscriber?.email || ''}
                        onChange={e => set('email', e.currentTarget.value)}
                    />
                    <Autocomplete
                        multiple
                        openOnFocus
                        disabled={loading}
                        disableCloseOnSelect
                        filterSelectedOptions
                        value={subscriber?.components || []}
                        isOptionEqualToValue={(o, v) => o?.id === v}
                        onChange={(_, values) => set('components', values.map(c => c?.id || c))}
                        getOptionLabel={c => c.name}
                        renderOption={(liProps, o) => (
                            <li {...liProps}><ComponentChip key={o?.id} color={o?.color} name={o?.name} style={{margin: 5}} /></li>
                        )}
                        renderTags={(coms, getTagProps) => {
                            return coms.map((c, index) => {
                                const com = props.components.find(co => co?.id === c);
                                return (
                                    <ComponentChip
                                        name={com.name}
                                        color={com.color}
                                        key={c?.id || c}
                                        {...getTagProps({ index })}
                                    />
                                );
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={t('components')}
                            />
                        )}
                        options={props.components}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row">
                    <Button
                        disabled={loading}
                        onClick={props.onClose}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        endIcon={!!loading && <CircularProgress size={16} />}
                        disabled={!canSave || loading}
                        onClick={() => {
                            setLoading(true);
                            if(props.fromAdmin) {
                                api.post('/api/subscribers', subscriber.toJS()).then(() => {
                                    setLoading(false);
                                    props.onClose();
                                });
                            } else {
                                api.put('/api/subscribers/me', {
                                    token,
                                    componentIds: subscriber.components
                                }).then(() => {
                                    setLoading(false);
                                    props.onClose();
                                });
                            }
                        }}
                    >
                        {t('save')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )

};

export default EditSubscriberDialog;