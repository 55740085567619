import React from 'react';

import {IconButton, Paper, Stack} from "@mui/material";

import ComponentChip from "./ComponentChip";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import TrashIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";


const ComponentSummary = ({component, onRequestUpdate, onRequestDelete}) => {
    const [t] = useTranslation();

    return (
        <Paper>
            <Box padding={2}>
                <Stack spacing={2}>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <ComponentChip name={component.name} color={component.color}/>
                        <Stack direction="row" alignItems="center">
                            <IconButton
                                onClick={onRequestUpdate}
                                size="small"
                            ><EditIcon/></IconButton>
                            <IconButton
                                onClick={onRequestDelete}
                                size="small"
                            ><TrashIcon/></IconButton>
                        </Stack>
                    </Stack>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <span>{t('subscribers')}: {component.subscribers.length}</span>
                        <span>{t('incidents')}: {component.incidents.length}</span>
                    </Stack>
                </Stack>
            </Box>
        </Paper>
    )

};

export default ComponentSummary;