import dayjs from "dayjs";
import {Record} from 'immutable';

import HighImpactIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import MediumImpactIcon from '@mui/icons-material/KeyboardArrowUp';
import LowImpactIcon from '@mui/icons-material/HorizontalRule';
import {Colors} from "../colors";
import Update from "./Update";

export const status = {
    detected: 0,
    investigating: 10,
    monitoring: 20,
    resolved: 30
};
export const statusName = {
    [status.detected]: 'status.detected',
    [status.investigating]: 'status.investigating',
    [status.monitoring]: 'status.monitoring',
    [status.resolved]: 'status.resolved'
}
export const statusNameAnnouncement = {
    [status.detected]: 'status.open',
    [status.resolved]: 'status.closed'
}
export const statusColor = {
    [status.detected]: Colors.danger,
    [status.investigating]: Colors.warning,
    [status.monitoring]: Colors.info,
    [status.resolved]: Colors.success
}
export const statusColorAnnouncement = {
    [status.detected]: Colors.info,
    [status.resolved]: Colors.success
}
export const impact = {
    low: 0,
    medium: 5,
    high: 10
}
export const impactName = {
    [impact.low]: 'impact.low',
    [impact.medium]: 'impact.medium',
    [impact.high]: 'impact.high'
}
export const impactIcon = {
    [impact.low]: LowImpactIcon,
    [impact.medium]: MediumImpactIcon,
    [impact.high]: HighImpactIcon
}
export const impactColor = {
    [impact.low]: Colors.info,
    [impact.medium]: Colors.warning,
    [impact.high]: Colors.danger
}

const IncidentRecord = Record({
    id: 0,
    name: '',
    uuid: '',
    opened: dayjs(),
    impact: 0,
    status: 0,
    type: 'incident',
    components: [],
    updates: [],
    subscribers: []
});

export default class Incident extends IncidentRecord {
    id;
    name;
    uuid;
    opened;
    impact;
    status;
    type;
    components;
    updates;
    subscribers;
    
    get isOpen() {
        return this.status !== status.resolved;
    }
    
    get lastUpdate() {
        return this.updates.map(u => new Update(u))
            .sort((a,b) => a.createdDate < b.createdDate ? 1 : -1)[0];
    }
}