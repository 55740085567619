import {Record} from 'immutable';
import dayjs from "dayjs";

const UpdateRecord = Record({
    id: 0,
    created: dayjs(),
    sent: null,
    message: '',
    status: 0
});

export default class Update extends UpdateRecord {
    id;
    created;
    sent;
    message;
    status;
    
    get createdDate() {
        return dayjs(this.created);
    }
    get sentDate() {
        return dayjs(this.sent);
    }
}