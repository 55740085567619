import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTranslation} from "react-i18next";

export default function AlertDialog(props) {
    const [t] = useTranslation();
    const {
        open,
        onClose,
        onConfirm,
        confirmText,
        onReject,
        rejectText,
        onCancel,
        cancelText,
        title,
        text
    } = props;

    const handleClose = (callback) => {
        if (callback) callback();
        onClose();
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {!!title && (
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
            )}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="inherit"    
                    onClick={() => handleClose(onCancel)}>
                    {cancelText || t('cancel')}
                </Button>
                {onReject && (
                    <Button
                        color="error"    
                        onClick={() => handleClose(onReject)}>
                        {rejectText || t('no')}
                    </Button>
                )}
                <Button
                    color="primary"
                    onClick={() => handleClose(onConfirm)} autoFocus>
                    {confirmText || t('yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}